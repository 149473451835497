<template>
  <div>
    <!-- 接入头部组件 -->
    <myheader></myheader>
    <!-- 中间新闻部分 -->
    <div class="container">
      <!-- 顶部图片 -->
      <div class="top">
        <img src="../../images/news.png" alt="" />
      </div>
      <!-- 中间新闻部分 -->
      <div class="middle">
        <!-- 新闻导航栏 -->
        <!-- 点击跳转--》传id -->
        <div class="navigation">
          <div class="nav" v-for="(cate, index) in category" @click="showByCategoryId(cate.id)" :key="'cate-' + index">
            {{ cate.name }}
          </div>
          <!-- <div class="nav" >技术文章</div>
                <div class="nav">活动通知</div> -->
        </div>

        <div class="content" @click="skipOneNews(nes.id)" v-for="(nes, index) in loadNewsData" :key="'new-' + index">
          <div class="news">
            <div class="shownew">
              <div class="newsImg"><img :src="cdn + nes.cover" alt="" /></div>
              <!-- 新闻描述 -->
              <div class="newState">
                <div class="title">{{ nes.title }}</div>
                <div class="describe">
                  <span v-html="ellipsis(nes.content)"></span>
                </div>
                <div class="button">查看详情</div>
              </div>
              <!-- <div class="data">
                <div class="month">{{ nes.publishDate }}</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 接入尾部组件 -->
    <mybottom></mybottom>
  </div>
  <!-- Header -->

  <!-- ROUTER -->
  <!-- <router-view /> -->
</template>

<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      loadNewsData: [],
      showCategoryId:0,
      category: [],
    };
  },
  async created() {
    await this.newslist();
    await this.showByCategoryId(this.category[0].id);
  },
  methods: {
    /**查看新闻 */
    skipOneNews(id) {
      //跳转至查看界面
      this.$router.push({
        name: 'loadOneNews',
        query: {
          id: id, //携带新闻编号
        },
      });
    },
    ellipsis(value) {
      if (!value) return '';
      value = this.delHtmlTag(value);
      if (value.length > 70) {
        return value.slice(0, 70) + '...';
      }
      return value;
    },
    async newslist() {
      let reqData = {};
      let loadNewSectionUrl = `/home/loadNewSection`;
      let result = await this.axios.get(loadNewSectionUrl, reqData);
      console.log(result);
      this.category = result.data.data;
    },
    async listNews() {
      let reqParam = { page: '1', pageSize: '20', sectionId: this.showCategoryId };
      let urlParam = `?sectionId=${reqParam.sectionId}&`;
      let loadNewsListUrl = `/home/listNews/${reqParam.page}/${reqParam.pageSize}${urlParam}`;
      let result = await this.axios.get(loadNewsListUrl);
      console.log(result);
      this.loadNewsData = result.data.data;

    },
    //不同分类显示不同新闻
    showByCategoryId(categoryId) {
      this.showCategoryId = categoryId;
      this.listNews();
    },
    delHtmlTag(str) {
      return str.replace(/<[^>]+>/g, ''); //正则去掉所有的html标记
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.container {
  margin-bottom: 100px;
}
// 顶部
.top {
  max-width: 1920px;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  // height: 420px;
  // background-image: url('../../images/news.png');
  // background-position: 50% 50%;
}

// 中间目录
.navigation {
  display: flex;
  width: auto;
  height: 60px;
  margin: 0 auto;
  justify-content: center;
  border-bottom: 1px solid #eeeeee;
  // margin-bottom: 50px;
}
.nav {
  width: 183px;
  height: 58px;
  font-size: 20px;
  line-height: 55px;
  text-align: center;
  cursor: pointer;
}
.nav:hover {
  background-color: #f2f2f2;
  color: #2e71fe;
}
// 新闻内容
.content {
  width: 1000px;
  margin: 0 auto;
  // margin-top: 50px;
  // box-sizing: border-box;
  // overflow: hidden;
  // background-color: aquamarine;
}
.news {
  display: flex;
  margin-top: 52px;
  cursor: pointer;
}
.shownew {
  display: flex;
  justify-content: center;
  align-items: center;
}
// 左图
.newsImg {
  width: 288px;
  height: 163px;
  margin-right: 40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.newState {
  width: 700px;
  box-sizing: border-box;
}
// 新闻
.title {
  font-size: 25px;
  margin-bottom: 27px;
  font-weight: 700;
}
.describe {
  font-size: 17px;
  margin-bottom: 15px;
  width: 650px;
}
.button {
  width: 120px;
  height: 35px;
  border: 1px solid #cccccc;
  color: #000000;
  text-align: center;
  line-height: 32px;
}
.button:hover {
  border: 1px solid #2e71fe;
  color: #2e71fe;
}

// 右边日期
.data {
  height: 50px;
  width: 180px;
  margin-top: 60px;
  box-sizing: border-box;
  margin-left: 45px;
  padding-left: 50px;
  border-left: 1px solid #e5e5e5;
}
.month {
  font-size: 20px;
  font-weight: 400;
}
.year {
  font-size: 15px;
  font-weight: 500;
}
</style>
